<template>
    <div v-if="bookingForm && ready" class="r-style" @contextmenu="handleClick($event)">
      <link href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900" rel="stylesheet">
      <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">
      <reservation-form-header></reservation-form-header>
      <section class="mt-3">
         <div class="container">
            <h1>{{tr('Booking Information Form')}}</h1>
            <hr>
            <reservation-form-main
                :langCode="langCode"
                :bookingForm.sync="bookingForm"
                :fieldOptions="fieldOptions"
                :invalid="invalid"
            ></reservation-form-main>
         </div>
      </section>
      <section>
         <div class="container">
            <h4>{{tr('PASSENGERS')}}</h4>
            <div  v-for="(pax,i) in bookingForm.BookingFormPaxs">
                <reservation-form-pax :pax.sync="pax" i="i" :langCode="langCode" @uploadPassport="uploadPassport"
                    :datesInvalids="datesInvalids" :invalid="invalid" :requiredFields="requiredFields"
                    :fieldOptions="fieldOptions"
                    :i="i"></reservation-form-pax>
            </div>
            <div class="row mt-2">
               <div class="col-12 text-center">
                  <button class="btn btn-primary btn-large" @click="addPax">{{tr('More Passengers')}}</button>
                  <button class="btn btn-primary" @click="removePax" v-if="bookingForm.BookingFormPaxs.length>1">{{tr('Cancel_')}}</button>
               </div>
            </div>
             <h6 v-if="paxMessage" class="mt-4"><i class="text-danger">{{paxMessage}}</i></h6>
         </div>
      </section>
      <section>
         <div class="container">
             <h4>{{tr('INTERNATIONAL FLIGHTS')}}</h4>
             <h6 class="text-center">{{tr('ENTRY FLIGHT')}}</h6>
             <div v-for="(flight,i) in bookingForm.BookingFormFlights" v-if="flight.FlightType=='ENTRY_FLIGHT'">
                <reservation-form-flight :flight.sync="flight" :langCode="langCode" @uploadTicket="uploadTicket"
                    :i="i"></reservation-form-flight>
             </div>
         </div>
      </section>
      <section v-if="departureFlights>0">
         <div class="container">
            <h6 class="text-center">{{tr('DEPARTURE FLIGHT')}}</h6>
             <div v-for="(flight,i) in bookingForm.BookingFormFlights" v-if="flight.FlightType=='DEPARTURE_FLIGHT'">
                <reservation-form-flight :flight.sync="flight" :langCode="langCode" @uploadTicket="uploadTicket"
                    :i="i"></reservation-form-flight>
             </div>
         </div>
      </section>
      <div class="container">
          <h6 v-if="flightsMessage" class="mt-4 text-center"><i class="text-danger">{{flightsMessage}}</i></h6>
      </div>
      <section>
         <div class="container" >
            <h4>{{tr('DOMESTIC FLIGHTS (COMPLETE ONLY IF YOU HAVE ALREADY PURCHASED INTERNAL FLIGHTS)')}}</h4>
             <div v-for="(flight,i) in bookingForm.BookingFormFlights" v-if="flight.FlightType=='DOMESTIC'">
                <reservation-form-flight :flight.sync="flight" :langCode="langCode" @uploadTicket="uploadTicket"
                    :i="i"></reservation-form-flight>
             </div>
            <div class="row mt-2">
               <div class="col-12 text-center">
                  <button class="btn btn-primary" @click="addFlight('DOMESTIC')">{{tr('More Flights')}}</button>
                  <button class="btn btn-primary" @click="removeFlight" v-if="domesticFlights>1">{{tr('Cancel_')}}</button>
               </div>
            </div>
         </div>
      </section>
      <section>
         <div class="container d-flex justify-content-center">
            <div class="row">
                <div class="col-12 text-center font-weight-bold">
                    <label v-if="attached.length>0">{{tr('DOCUMENTS ALREADY SENT')}}:</label>
                    <label v-else>{{tr('NO DOCUMENTS ATTACHED')}}</label>
                </div>
                <div class="col-12 text-center font-weight-bold">
                    {{attached.join(',  ')}}
               </div>
             </div>
         </div>
      </section>
      <section>
         <div class="container d-flex justify-content-center">
            <div class="row">
                <div class="col-12 row mt-2 text-justify">
                    {{tr('_legal')}}
                </div>
                <div class="col-12 row mt-2">
                    <div class="custom-control custom-checkbox">
                         <input type="checkbox" class="custom-control-input" id="customCheck1" v-model="accept" >
                         <label class="custom-control-label" for="customCheck1">{{tr('I accept the')}}
                            <a :href="tr('_terms_link')" target="_blank">{{tr('terms and conditions')}} </a>
                         </label>
                    </div>
                </div>
                <div class="col-12 row mt-2 d-flex justify-content-center alert alert-success" v-if="response">
                    <label>{{response}}</label>
                </div>
                <div class="col-12 row mt-2 d-flex justify-content-center alert alert-success" v-if="response">
                    <label v-if="attachments">{{tr('Sending attachments')}}: {{progress}}/{{attachments}}</label>
                </div>
                <div class="col-12 row mt-2 d-flex justify-content-center alert alert-danger" v-if="responseError">
                    <label>{{responseError}}</label>
                </div>
                <div class="col-12 row mt-2">
                     <button type="button" class="btn btn-send w-100" @click="submit" :disabled="!accept || processing">
                        <span v-if="!processing">{{tr('Send')}}</span>
                        <img class="processing-submit" src="@/img/loading.gif" v-else>
                     </button>
                </div>
                <div class="row mt-2" v-if="errors">
                    <div class="col-md-0">
                        <font-awesome-icon icon="exclamation-triangle" class="fields-error"/>
                    </div>
                    <div class="col-md-10">
                        <label class="fields-error">{{tr('FIELDS_ERRORS')}}</label>
                    </div>
                </div>
            </div>
         </div>
      </section>
      <reservation-form-footer></reservation-form-footer>
    </div>
</template>


<script>
import '@/extra/custom/css/r-style.scss';
const reservationForm = importVueComp('components/reservationform', 'ReservationForm', 'custom');
import dateSelector from '@/components/tools/DateSelector';
let requiredFields = reservationForm.data().requiredFields;
requiredFields.push('SpeakSpanish');
requiredFields.push('SharePictures');
requiredFields.push('ContactName');
requiredFields.push('ContactPhone');
requiredFields.push('Insurance');
export default {
    name: 'custom-reservation-form',
    mixins: [reservationForm],
    mounted () {
        this.flightsMessage = this.tr('_flight_message');
    },
    data () {
        return {
            requiredFields: requiredFields
        }
    }
}
</script>

