<template>
    <div class="">
    </div>
</template>

<script>
export default {
    name: 'itinerary-prices-name',
    props: ['t', 'roomTypeNames'],
}
</script>


