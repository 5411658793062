<script>
const photoGallery  = importVueComp('components/tools', 'PhotoGallery', 'custom');

export default {
    name:"custom-photo-gallery",
    mixins:[photoGallery],
    computed: {
        imagesList () {
          if (!this.images) return [];
          return this.images.filter(image => image.src).slice(0,3);
        },
    }
}

</script>