<template>
    <div class="fstyles mt-3">
        <img v-if="firstDayImage" :src="firstDayImage.url" alt="" class="banner-image w-100 d-block mb-4">

        <div class="row">
            <h3 class="col-12">
                {{toTitleCase(tr('Itinerary'))}} {{tr('from')}} {{startDay}} {{startMonth}} {{tr('to')}} {{endDay}} {{endMonth}}
            </h3>
        </div>
        <div class="row">
            <div class="col-12">
                <b>{{tr('Passengers')}}</b>: {{passengersNames}}
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <b>{{tr('Reservation Code')}}</b>: {{flights}}
            </div>
        </div>
        <div class="row">
            <div class="col-12" v-if="getFlightsList.length">
                <text-rows-style tag="p" :text="f" v-for="f of getFlightsList" v-bind:key="f.id"></text-rows-style>
            </div>
        </div>
        <div class="col-12 mt-3">
          <ul class="border border-dark day-header">
                <li>{{tr("_pick-up time")}}</li>
                <li>{{tr("_airport_hotel")}}</li>
                <li>{{tr("_national_park")}}</li>
            </ul>
        </div>
        <div class="row">
            <div class="col-12 mt-5 shadow py-1" v-for="(day,idx) of recordStore.BookingDays" v-if="!skipDays[day.DayNr]">
                <div class="mb-3 day-header">
                    <div class="d-flex align-items-center">
                        <div class="icon not-break-inside day-number print-hide mr-2">
                            <h4 class="d-flex justify-content-center">Day {{getDayNumber(day)}}</h4>
                        </div>
                        <h3 class="font-weight-bold gray">
                            {{getDatesText(day)}}:
                            {{getCitiesNames(day)}}
                            <span v-if="day.Title"> - {{day.Title}}</span>
                        </h3>
                    </div>
                </div>
                <booking-final-preview-day-service v-for="(s,i) of day.BookingDayServices" :service="s" :langCode="langCode" :key="s.id" :day="day"/>
                <div v-if="hotelsByDay[day.DayNr] || day.PaxHotel==1" class="mt-3">
                    <div v-if="hotelsByDay[day.DayNr]">
                      <u><b>{{tr('Accommodation')}}</b></u> {{tr('at')}} {{getHotelName(day)}}
                    </div>
                    <div v-else-if="day.PaxHotel==1">
                      <strong>{{tr('Accommodation')}}</strong> {{tr('Booked by yourself')}}
                    </div>
                </div>

            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h4>{{tr('End our services')}}</h4>
            </div>
        </div>
    </div>
</template>

<script>
const bookingFinalPreview = importVueComp('components/documentation', 'BookingFinalPreview', 'custom');
const bookingFinalPreviewDayService = importVueComp('components/documentation', 'BookingFinalPreviewDayService');
import { mapState } from 'vuex';
export default {
    name: 'custom-booking-final-preview',
    mixins: [bookingFinalPreview],
    components: {
        'booking-final-preview-day-service': bookingFinalPreviewDayService,
    },
    computed: {
        firstDayImage () {
            for (let day of this.recordStore?.BookingDays) {
                if (day.ImageId) return day.Image;
                for (let s of day.BookingDayServices) {
                    for (let row of s.BookingDayServiceImages) {
                        if (row.ImageId) {
                            return row.Image;
                        }
                    }
                }
            }
        },
        hotelsByDay () {
            return this.recordStore.hotelsByDay;
        },
        startDay () {
            if (this.recordStore) return moment(this.recordStore.StartDate).format("DD");
        },
        endDay () {
            if (this.recordStore) return moment(this.recordStore.EndDate).format("DD");
        },
        flights () {
            let reservation_number = []
            for (let i in this.recordStore.flights) {
                if (this.recordStore.flights[i].Flights) {
                    for (let j of this.recordStore.flights[i].Flights) {
                        reservation_number.push(j.ReservationNumber)
                    }
                }
            }
            return reservation_number.join(', ');
        },
        startMonth () {
            if (this.recordStore) return this.tr(moment(this.recordStore.StartDate).format("MMM"));
        },
        endMonth () {
            if (this.recordStore) return this.tr(moment(this.recordStore.EndDate).format("MMM"));
        },
        passengersNames () {
            if (this.passengerList.length>0) {
                return this.passengerList.join(' / ');
            }
            return this.toTitleCase(this.recordStore.PaxName);
        },
    },
    methods: {
        getHotelName (day) {
            let res = [];
            let ids = {};
            if (this.hotelsByDay[day.DayNr] && this.hotelsByDay[day.DayNr].BookingDayRooms) {
                for (let room of this.hotelsByDay[day.DayNr].BookingDayRooms) {
                    if (!room.Hotel) continue;
                    if (ids[room.Hotel.id]) continue;
                    ids[room.Hotel.id] = true;
                    res.push(`${room.Hotel.Name}, ${room.Hotel.Address}`);
                }
            }
            return res.join('; ');
        },
        toTitleCase (t) {
            return tools.toTitleCase(t);
        }


    },
}
</script>

<style scoped>
.banner-image {
    max-height: 500px;
    object-fit: cover;
}
.icon h4 {
    width: 72px;
    height: 72px;
    background: #fa4c06;
    border-radius: 50px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #fff;
    font-size: 0.8vw;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: -0.1em;
    transition: ease-in-out 0.3s;
    border: 6px solid #fdfdfd;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.22);
}

/* .shadow {
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
} */

.gray {
    color: #5c5e62;
}
</style>

