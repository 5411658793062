
<script>
const bookingQuoteTotal = importVueComp('components/booking', 'BookingQuoteTotal', 'custom');
export default {
    name: 'custom-booking-quote-total',
    mixins: [bookingQuoteTotal],
    methods: {
        canOverwrite (pType) {
            if (this.recordStore.Totals[this.baseId][pType].OverPrice) {
                return true;
            }
            return false;
        },
    },
}
</script>

